<template>
  <div>
    <b-loading :is-full-page="true" :active.sync="isLoading" />

    <div v-if="passcodeEnabled && !getPreviouslyLoggedIn()" class="centered">
      <form @submit.prevent="submitPasscode" @keyup.enter="submitPasscode()">
        <div class="columns">
          <div class="column">
            <b-field :label="$t('passcode-input-label')" style="padding-top: 1em; text-align: center">
              <b-input
                v-model="passcode"
                style="width: 250px"
                :placeholder="$t('passcode-input-placeholder')"
                type="password"
                password-reveal
                @keyup.native.enter="submitPasscode"
              />

              <b-button class="button is-primary" :disabled="!passcode" @click="submitPasscode">{{
                $t('passcode-button-label')
              }}</b-button>
            </b-field>
            <p v-if="passcodeFailed" class="is-size-6" style="text-align: center; color: red">
              {{ $t('passcode-incorrect-message') }}
            </p>
          </div>
        </div>
      </form>
    </div>

    <p
      v-else
      class="has-text-weight-semibold is-size-4 has-text-grey has-text-centered is-italic"
      style="padding-top: 5em"
    >
      {{ $t('app-finding-appointment-text') }}
    </p>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  data() {
    return {
      passcode: undefined,
      passcodeFailed: false,
    };
  },
  computed: {
    ...mapGetters(['isLoading', 'token']),
  },
  async activated() {
    this.setToken(this.$route.query.token);
    this.passcode = this.getPasscode();
    if (!this.passcode) this.storePreviouslyLoggedIn(false);
    if (this.accessGranted()) {
      // If passcode not required or we've already successfully entered with one, then load home page immediately,
      // else this will be called when passcode is submitted
      this.loadHomePage();
    }
  },
  methods: {
    ...mapActions(['setOmwData', 'setToken', 'setContactCentre', 'setClickOrigin']),

    async submitPasscode() {
      this.storePasscode(this.passcode);
      this.loadHomePage();
    },

    async loadHomePage() {
      if (await this.getData()) await this.routeToHomePage();
      else this.passcodeFailed = true;
    },

    async routeToHomePage() {
      const query = {
        token: this.token,
      };
      if (this.$route.query.marketingPreviewUrl) {
        // If this being called as result of a preview from the portal, propagate the preview url
        query.marketingPreviewUrl = this.$route.query.marketingPreviewUrl;
        query.clickUrl = this.$route.query.clickUrl;
      }
      const contactCentre = this.convertTrueFalseStringToBoolean(this.$route.query.contactCentre);

      this.setContactCentre(contactCentre);
      this.$router
        .push({
          name: 'Home',
          query,
        })
        .catch((_err) => {
          /* noop */
        });
    },
  },
});
</script>

<style scoped lang="scss">
@import '@/sass/variables.scss';

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-background {
  background-color: $header-background;
}
</style>
