import Vue from 'vue';
import VueRouter from 'vue-router';
import OnMyWay from '@/views/Home.vue';
import CustomLoading from '@/components/CustomLoading.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: OnMyWay,
  },
  {
    path: '/',
    name: 'Loading',
    component: CustomLoading,
  },
  {
    path: '/cancelled',
    name: 'Cancelled',
    component: () =>
      import(
        /* webpackChunkName: "Cancelled" */ /* webpackPrefetch: true */ '@/components/appointmentBooking/AppointmentCancelConfirmation.vue'
      ),
  },
  {
    path: '/rebook',
    name: 'Rebook',
    component: () =>
      import(
        /* webpackChunkName: "Rebook" */ /* webpackPrefetch: true */ '@/components/appointmentBooking/AppointmentRebookContainer.vue'
      ),
  },
  {
    path: '/confirmed',
    name: 'Confirmed',
    component: () =>
      import(
        /* webpackChunkName: "Confirmed" */ /* webpackPrefetch: true */ '@/components/appointmentBooking/AppointmentRebookConfirmation.vue'
      ),
  },
  {
    path: '/photo/manage',
    name: 'PhotoManage',
    component: () =>
      import(/* webpackChunkName: "PhotoMange" */ /* webpackPrefetch: true */ '@/components/photos/PhotoManage.vue'),
  },
  {
    path: '/photo/manage/attach',
    name: 'PhotoAttach',
    component: () =>
      import(/* webpackChunkName: "PhotoAttach" */ /* webpackPrefetch: true */ '@/components/photos/PhotoAttach.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: import.meta.env.BASE_URL,
  routes,
});

export default router;
